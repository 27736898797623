import { Injectable } from '@angular/core';
import { defer, from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  DynamicLoaderAssets,
  DynamicLoaderAssetsWithToken,
  DynamicLoaderInlineAssets,
  DynamicLoaderResponse,
  DynamicLoaderStatuses,
} from '../domain';

@Injectable({
  providedIn: 'root',
})
export class DynamicAssetLoaderService {
  private loaded: { [key: string]: boolean | null } = {};

  /**
   * Load an asset when you need to do something after it has loaded.
   *
   * e.g: I need to load this library before I call a function from it.
   *
   * This should only be used when you need to do something after the script has loaded.
   *
   * @param assets (DynamicLoaderAssets | DynamicLoaderAssetsWithToken)[]
   *
   * @returns Observable<boolean>
   */
  public load(...assets: (DynamicLoaderAssets | DynamicLoaderAssetsWithToken)[]): Observable<boolean> {
    const promises: Promise<DynamicLoaderResponse>[] = assets.map((asset) => this.loadAsset(this.getAssetUrl(asset)));

    return from(defer(() => Promise.all(promises))).pipe(
      take(1),
      map((assetStatuses) => {
        const failure = assetStatuses.find((asset) => asset.status === DynamicLoaderStatuses.Failed);
        if (failure) {
          console.warn(`Asset could not be loaded dynamically: ${failure.asset}`);
          // throw new Error(`Asset could not be loaded dynamically: ${failure.asset}`);
        }

        return true;
      }),
    );
  }

  /**
   * Load an asset when you don't need to do anything after it has loaded.
   *
   * e.g: This library needs to be loaded, but I am not doing anything with it
   *
   * @param asset DynamicLoaderAssets | DynamicLoaderAssetsWithToken
   */
  public appendAsset(asset: DynamicLoaderAssets | DynamicLoaderAssetsWithToken): void {
    const url = this.getAssetUrl(asset);

    if (!this.loaded[url]) {
      const element = this.getElementByExtension(url);
      this.appendToDocumentHead(element);
      this.loaded[url] = true;
    }
  }

  /**
   * Add an inline script to the DOM
   *
   * @param asset DynamicLoaderInlineAssets
   * @param code string
   */
  public appendInlineAsset(asset: DynamicLoaderInlineAssets, code: string): void {
    if (!this.loaded[asset]) {
      const element = this.baseScriptElement();
      element.text = code;
      this.appendToDocumentHead(element);

      this.loaded[asset] = true;
    }
  }

  /**
   * Add an inline asset with a custom tag and custom innerHtml to the DOM
   *
   * @param asset DynamicLoaderInlineAssets
   * @param elementName string
   * @param elementInnerHtml string
   */
  public appendInlineAssetWithCustomContent(asset: DynamicLoaderInlineAssets, elementName: string, elementInnerHtml: string): void {
    if (!this.loaded[asset]) {
      const element: HTMLElement = document.createElement(elementName);
      element.innerHTML = elementInnerHtml;
      this.appendToDocumentHead(element);

      this.loaded[asset] = true;
    }
  }

  private assetPromise(asset: string, element: HTMLElement): Promise<DynamicLoaderResponse> {
    return new Promise((resolve, _reject) => {
      element.onload = () => {
        this.loaded[asset] = true;
        resolve({ asset, status: DynamicLoaderStatuses.Loaded });
      };

      element.onerror = (_error) => {
        this.loaded[asset] = false;
        return resolve({ asset, status: DynamicLoaderStatuses.Failed });
      };

      this.appendToDocumentHead(element);
    });
  }

  private appendToDocumentHead(element: HTMLElement) {
    document.getElementsByTagName('head')[0].appendChild(element);
  }

  private baseScriptElement() {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    return scriptTag;
  }

  private getAssetUrl(asset: DynamicLoaderAssets | DynamicLoaderAssetsWithToken): string {
    return (asset as DynamicLoaderAssetsWithToken).asset
      ? (asset as DynamicLoaderAssetsWithToken).asset.replace('{TOKEN}', (asset as DynamicLoaderAssetsWithToken).token)
      : (asset as string);
  }

  private getElementByExtension(asset: string): HTMLElement {
    if (asset.endsWith('.css')) {
      return this.styleElement(asset);
    }

    return this.scriptElement(asset);
  }

  private loadAsset(asset: string): Promise<DynamicLoaderResponse> {
    if (this.loaded[asset] === undefined || this.loaded[asset] === false) {
      this.loaded[asset] = null;
      const element = this.getElementByExtension(asset);
      return this.assetPromise(asset, element);
    }

    return new Promise((resolve, _reject) => resolve({ asset, status: DynamicLoaderStatuses.AlreadyLoaded }));
  }

  private scriptElement(asset: string): HTMLElement {
    const scriptTag = this.baseScriptElement();
    scriptTag.src = asset;
    scriptTag.async = true;
    scriptTag.defer = true;

    return scriptTag;
  }

  private styleElement(asset: string): HTMLElement {
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    linkTag.href = asset;
    return linkTag;
  }
}
