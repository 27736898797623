import { ViewportRuler } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlatformService } from '@rma/generic/util/environment';
import { Observable, filter, map, startWith } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ViewportHeightService {
  public viewportHeight$: Observable<number>;

  constructor(
    private readonly viewportRuler: ViewportRuler,
    private readonly platformService: PlatformService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.viewportHeight$ = this.viewportRuler.change(300).pipe(
      startWith(true),
      filter(() => this.platformService.isPlatformBrowser),
      map(() => this.viewportRuler.getViewportSize().height),
    );

    this.viewportHeight$.pipe(untilDestroyed(this)).subscribe((height) => {
      this.document.documentElement.style.setProperty('--vh', `${height}px`);
    });
  }
}
