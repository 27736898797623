export const RmaBreakpoint = {
  ['XS']: '(max-width: 40em)',
  ['SM']: '(min-width: 40em) and (max-width: 47.999em)',
  ['MD']: '(min-width: 48em) and (max-width: 63.999em)',
  ['LG']: '(min-width: 64em) and (max-width: 79.999em)',
  ['XL']: '(min-width: 80em) and (max-width: 95.999em)',
  ['2XL']: '(min-width: 96em)',
  ['Handset']: '(max-width: 40em) and (orientation: portrait), (max-width: 47.999rem) and (orientation: landscape)',
  ['HandsetLandscape']: '(max-width: 48rem) and (orientation: landscape)',
  ['HandsetPortrait']: '(max-width: 40em) and (orientation: portrait)',
  ['Tablet']: '(max-width: 48em) and (orientation: portrait), (max-width: 63.999rem) and (orientation: landscape)',
  ['TabletLandscape']: '(max-width: 63.999rem) and (orientation: landscape)',
  ['TabletPortrait']: '(max-width: 47.999em) and (orientation: portrait)',
  ['>SM']: '(min-width: 48em)',
  ['>MD']: '(min-width: 64em)',
  ['>LG']: '(min-width: 80em)',
  ['>XL']: '(min-width: 96em)',
  ['<SM']: '(max-width: 39.999em)',
  ['<MD']: '(max-width: 47.999em)',
  ['<LG']: '(max-width: 63.999em)',
  ['<XL']: '(max-width: 79.999em)',
  ['<2XL']: '(max-width: 95.999em)',
} as const;

export type RmaBreakpoint = (typeof RmaBreakpoint)[keyof typeof RmaBreakpoint];
