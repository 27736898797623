import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { rmaShareReplay } from '@rma/generic/util/operators/combined';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { RmaBreakpoint } from './breakpoints';

@Injectable({
  providedIn: 'root',
})
export class DeviceSizeService {
  private stateMap = new Map<RmaBreakpoint, Observable<boolean>>();

  public constructor(private readonly breakpointObserver: BreakpointObserver) {}

  public observe(breakpoint: RmaBreakpoint): Observable<boolean> {
    if (this.stateMap.has(breakpoint)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.stateMap.get(breakpoint)!;
    }
    const observe$ = this.breakpointObserver.observe(breakpoint).pipe(
      map((state: BreakpointState) => state.matches),
      distinctUntilChanged(),
      rmaShareReplay(),
    );

    this.stateMap.set(breakpoint, observe$);

    return observe$;
  }

  public isMatched(breakpoint: RmaBreakpoint): boolean {
    return this.breakpointObserver.isMatched(breakpoint);
  }
}
